import { render, staticRenderFns } from "./personPopedom-detail.vue?vue&type=template&id=18d27d11&scoped=true"
import script from "./personPopedom-detail.vue?vue&type=script&lang=js"
export * from "./personPopedom-detail.vue?vue&type=script&lang=js"
import style0 from "./personPopedom-detail.vue?vue&type=style&index=0&id=18d27d11&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18d27d11",
  null
  
)

export default component.exports