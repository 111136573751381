import request from '@/utils/request'
import constant from '@/constant'

function queryBuildingList(obj) {
  var formData = new FormData();
  var str = Object.prototype.toString.call(obj);

  if(str.indexOf("FormData")==-1){
    for(var key in obj){
      formData.append(key, obj[key]);
    }
  }
  else{
    formData = obj;
  }

  return request.post(constant.serverUrl + "/base/communityBuilding/queryBuildingList", formData);
}

function add(formModel) {
  return request.post(constant.serverUrl + "/base/communityBuilding/add", formModel, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function create(){
  return request.get(constant.serverUrl + "/base/communityBuilding/create");
}

function edit(id){
  return request.get(constant.serverUrl + "/base/communityBuilding/edit/" + id);
}

function update(formModel){  
  return request.post(constant.serverUrl + "/base/communityBuilding/update", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function batchDelete(idList) {
  return request.post(constant.serverUrl + "/base/communityBuilding/batchDelete",idList,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

export default {
    queryBuildingList,add,create,edit,update,batchDelete
}