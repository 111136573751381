import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData) {
  return request.post(constant.serverUrl + "/base/communityRoom/pageList", formData);
}

function findByCommunityId(communityId) {
  return request.get(constant.serverUrl + `/base/communityRoom/findByCommunityId/${communityId}`);
}

function findByUnitId(unitId) {
  return request.get(constant.serverUrl + `/base/communityRoom/findByUnitId/${unitId}`);
}

function batchDelete(params) {
  return request.post(constant.serverUrl + "/base/communityRoom/batchDelete",params,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function create(){
  return request.get(constant.serverUrl + "/base/communityRoom/create");
}

function edit(id){
  return request.get(constant.serverUrl + "/base/communityRoom/edit/" + id);
}

function add(formModel){
  return request.post(constant.serverUrl + "/base/communityRoom/add", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function update(formModel){  
  return request.post(constant.serverUrl + "/base/communityRoom/update", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

export default {
  pageList,batchDelete,create,edit,add,update,findByCommunityId,findByUnitId
}