import request from '@/utils/request'
import constant from '@/constant'

function findRelatedIcon(personPopedomId){
    return request.get(constant.serverUrl + `/base/personPopedomIcon/findRelatedIcon?personPopedomId=${personPopedomId}`);
}

function saveRelatedIcon(personPopedomId,idList){
    var formData = new FormData();
  
    formData.append("personPopedomId",personPopedomId);
    formData.append("idList",idList.join(","));
  
    return request.post(constant.serverUrl + "/base/personPopedomIcon/saveRelatedIcon",formData);
}

export default {
    findRelatedIcon, saveRelatedIcon
}