
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :append-to-body="true"
    style="text-align: left"
    @close="closeDialog"
    :close-on-click-modal="false"
  >
    <div class="user-panel" v-loading="loading">
      <el-form
        ref="form"
        :model="formModel"
        :rules="ruleValidate"
        :label-width="'100px'"
      >
        <el-form-item label="关联场景" prop="sceneId">
          <el-select
            v-model="formModel.sceneId"
            filterable
            placeholder="请选择"
            style="width:300px;"
          >
            <el-option
              v-for="result in sceneData"
              :key="result.id"
              :label="result.sceneName"
              :value="result.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="单位" prop="companyId">
          <el-select-tree
            :props="props"
            :options="companyResult"
            v-model="formModel.companyId"
            size=""
            height="200"
            style="width: 300px"
          ></el-select-tree>
        </el-form-item>
        <!-- <el-form-item label="机构" prop="companyStructureId">
          <el-select-tree
            :props="companyStructureProps"
            :options="companyStructureResult"
            v-model="formModel.companyStructureId"
            height="200"
            size=""
          ></el-select-tree>
        </el-form-item> -->
        
        <el-form-item
          label="服务公司"
          prop="serviceCompanyId"
          v-if="sceneId == '4'"
        >
          <el-select-tree
            :props="props"
            :options="companyResult"
            v-model="formModel.serviceCompanyId"
            height="200"
            style="width: 300px"
          ></el-select-tree>
        </el-form-item>
        <el-form-item label="身份" prop="personRoleId">
          <el-select
            v-model="formModel.personRoleId"
            filterable
            placeholder="请选择"
            style="width: 300px"
          >
            <el-option
              v-for="role in personRoleResult"
              :key="role.id"
              :label="role.personRoleName"
              :value="role.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="进行注册审核" prop="enableRegisterExamine" v-if="havingIcon">
          <el-radio v-model="formModel.enableRegisterExamine" :label="true">是</el-radio>
          <el-radio v-model="formModel.enableRegisterExamine" :label="false">否</el-radio>
        </el-form-item>
        
        <!-- <el-form-item label="展示的图标" prop="showIconId">
          <el-select
            v-model="formModel.showIconId"
            filterable
            multiple
            placeholder="请选择"
            style="width: 280px"
          >
            <el-option
              v-for="icon in iconResult"
              :key="icon.iconId"
              :label="icon.iconName"
              :value="icon.iconId"
            ></el-option>
          </el-select>
        </el-form-item> -->

        <!--如果场景是社区住宅，且用户身份是业主、家属、租户之一，则选择关联房间-->
        <template v-if="formModel.sceneId=='2' && (['6','7','8'].indexOf(formModel.personRoleId)!=-1)">
          <el-form-item label="选择房屋" prop="companyStructureId">
            <el-select size="small" v-model="buildingId" style="width:100px;">
              <el-option
                v-for="item in buildingList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
            <el-select size="small" v-model="unitId" style="width:100px;">
              <el-option
                v-for="item in unitList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
            <el-select size="small"
              v-model="formModel.companyStructureId"
              filterable 
              placeholder="请选择"
              style="width:100px;">
              <el-option 
                v-for="room in roomList"
                :key="room.id"
                :label="room.number" 
                :value="room.id" 
              ></el-option>
            </el-select>

          </el-form-item>
        </template>
        
        <el-form-item label="备注信息" prop="remark">
          <el-input
            v-model="formModel.remark"
            placeholder="请输入备注信息"
            style="width: 300px"
          ></el-input>
        </el-form-item>

        <el-form-item label="是否要求考勤" prop="enableAttendance">
            <el-switch
              v-model="formModel.enableAttendance"
              active-color="#13ce66"
              inactive-color="#ff4949"
            ></el-switch>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import personPopedomApi from "@/api/base/personPopedom";
import personRoleInfoApi from "@/api/base/personRoleInfo";
import companyInfoApi from "@/api/base/companyInfo";
import deviceInfoApi from "@/api/base/deviceInfo";
import companyStructureInfoApi from "@/api/base/companyStructureInfo";
import communityRoomApi from '@/api/base/communityRoom';
import communityBuildingApi from '@/api/base/communityBuilding';

import iconInfoApi from "@/api/base/iconInfo";
import sceneInfoApi from "@/api/base/sceneInfo";
import SelectTree from "@/components/SelectTree";
import { Breadcrumb } from "element-ui";

export default {
  components: {
    "el-select-tree": SelectTree,
  },
  props: ["businessKey", "title", "personId"],
  data() {
    return {
      formModel: { companyId: "" },
      ruleValidate: {
        companyId: [
          { required: true, message: "对应公司不能为空", trigger: "blur" },
        ],
        sceneId: [
          { required: true, message: "关联场景值不能为空", trigger: "blur" },
        ],
        personRoleId: [
          { required: true, message: "对应角色不能为空", trigger: "blur" },
        ],
      },
      showDialog: true,
      loading: false,
      submitting: false,
      companyResult: [],
      treeData: [],
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children",
      },
      personRoleResult: [],
      iconResult: [],
      companyStructureResult: [],
      companyStructureProps: {
        // 配置项（必选）
        value: "id",
        label: "structureName",
        children: "children",
      },
      sceneData: [],
      sceneId: "",
      oldPersonRoleId: "",
      havingIcon: false,
      buildingList: [],
      unitList: [],
      roomList: [],
      buildingId: "",
      unitId: ""
    };
  },
  created() {
    var self = this;
    companyInfoApi.treeList().then(function (response) {
      var jsonData = response.data;
      if (jsonData.result) {
        if (jsonData.data != null && jsonData.data != "") {
          self.companyResult = jsonData.data;
        }
      }
    });

    sceneInfoApi.list().then((response) => {
      var jsonData = response.data;
      this.sceneData = jsonData.data;
    });

    // var formData = new FormData();
    // formData.append("companyId", "c52b03cb-7526-4e8b-8b90-4f7a6a37b586");
    // personRoleInfoApi.listByCompanyId(formData).then(function (response) {
    //   var jsonData = response.data;
    //   if (jsonData.result) {
    //     if (jsonData.data != null && jsonData.data != "") {
    //       self.personRoleResult = jsonData.data;
    //     }
    //   }
    // });
  },
  watch: {
    "buildingId": function(newVal) {
      console.log(`buildingId=${newVal}`);
      if(newVal.length>0){
        console.log(`buildingList=${this.buildingList.length}`);

        var filterList = this.buildingList.filter(item=>item.id==newVal);

        console.log(`filterList=${filterList.length}`);
        if(filterList.length>0) {
          this.unitList = filterList[0].unitList;
        }
      }
      else{
          this.unitList = [];
      }

      if(this.unitList.filter(unit=>unit.id==this.unitId).length==0){
          this.unitId = "";
      }
    },
    "unitId": function(newVal) {
      console.log(`unitId=${newVal}`);

      if(newVal.length>0){
          communityRoomApi.findByUnitId(newVal).then((response)=>{
            var jsonData = response.data;
            
            if (jsonData.result) {
              this.roomList = jsonData.data;
            }
          });
      }
      else{
          this.roomList = [];
      }
    },
    "formModel.companyId": function (val, oldval) {
      if (val != null && val != "") {
        var self = this;

        var formData = new FormData();
        formData.append("companyId", val);
        personRoleInfoApi.listByCompanyId(formData).then(function (response) {
          var jsonData = response.data;
          if (jsonData.result) {
            if (jsonData.data != null && jsonData.data != "") {
              self.personRoleResult = jsonData.data;
            }
          }
        });

        // companyStructureInfoApi.list(formData).then(function (response) {
        //   var jsonData = response.data;
        //   if (jsonData.result) {
        //     if (jsonData.data != null && jsonData.data != "") {
        //       self.companyStructureResult = jsonData.data;
        //     }
        //   }
        // });

        // communityRoomApi.findByCommunityId(val).then((response)=>{
        //   var jsonData = response.data;
          
        //   if (jsonData.result) {
        //     self.roomList = jsonData.data;
        //   }
        // });

        communityBuildingApi.queryBuildingList({
          "communityId": val
        })
        .then(resp=>{
          var jsonData = resp.data;

          if(jsonData.result){
              this.buildingList = jsonData.data;

              if(this.buildingList.length>0 && this.formModel.companyStructureId!=null){
                this.queryRoom(this.formModel.companyStructureId);
              }
          }
        });
        
        if (self.oldPersonRoleId == "") {
          companyInfoApi.edit(val).then((response) => {
            var jsonData = response.data;
            if (jsonData.result) {
              self.formModel.sceneId = jsonData.data.sceneId;
            }
          });
        }
      }
    },
    "formModel.sceneId": function (val, oldval) {
      if (val != null && val != "") {
        var self = this;
        var formData = new FormData();
        formData.append("sceneId", val);
        personRoleInfoApi.findBySceneId(formData).then(function (response) {
          var jsonData = response.data;
          if (jsonData.result) {
            if (jsonData.data != null && jsonData.data != "") {
              // if (self.oldPersonRoleId != "") {
              //   self.formModel.personRoleId = self.oldPersonRoleId;
              // } else {
              //   self.formModel.personRoleId = "";
              //   self.oldPersonRoleId = "";
              // }
              self.personRoleResult = jsonData.data;
            }
          }
        });
      }
    },
    "formModel.personRoleId": function (val, oldval) {
      // if (val != null && val != "") {
      //   var self = this;
      //   var formData = new FormData();
      //   formData.append("personRoleId", val);
      //   formData.append("iconId", "73");

      //   personRoleInfoApi.findHaveIcon(formData).then(function (response) {
      //     var jsonData = response.data;
      //     if (jsonData.result) {
      //          self.havingIcon = jsonData.data; 
      //     }
      //   }); 
      // }
    }
    // "formModel.personRoleId": function (val, oldval) {
    //   if (val != null && val != "") {
    //     var self = this;
    //     self.formModel.showIconId = "";
    //     var formData = new FormData();
    //     formData.append("roleId", val);

    //     iconInfoApi.listByRoleId(formData).then(function (response) {
    //       var jsonData = response.data;
    //       if (jsonData.result) {
    //         if (jsonData.data != null && jsonData.data != "") {
    //           self.iconResult = jsonData.data;
    //         }
    //       }
    //     });
    //   }
    // },
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;
      self.formModel.personId = self.personId;
      // if (
      //   self.formModel.showIconId != null &&
      //   self.formModel.showIconId != ""
      // ) {
      //   var showIconId = self.formModel.showIconId.join(",");
      //   self.formModel.showIconId = showIconId;
      // } else {
      //   self.formModel.showIconId = "";
      // }

      if (
        self.formModel.companyStructureId != null &&
        self.formModel.companyStructureId == "0"
      ) {
        self.$message({
          message: "请选择下级机构",
          type: "warning",
        });
        self.formModel.companyStructureId == null;
      } else {
        this.$refs["form"].validate((valid) => {
          if (valid) {
            (function () {
              var id = self.formModel.id;

              if (id == null || id.length == 0) {
                return personPopedomApi.add(self.formModel);
              } else {
                return personPopedomApi.update(self.formModel);
              }
            })().then(function (response) {
              var jsonData = response.data;

              if (jsonData.result) {
                self.$message({
                  message: "保存成功!",
                  type: "success",
                });

                self.$emit("close", true);
              } else {
                self.$message({
                  message: jsonData.message + "",
                  type: "warning",
                });
                self.formModel.companyStructureId == null;
                self.$emit("close", false);
              }
            });
          }
        });
      }
    },
    queryRoom(roomId) {
      communityRoomApi.edit(roomId).then(resp=>{
        var jsonData = resp.data;

        if(jsonData.result){
          this.buildingId = jsonData.data.buildingId;
          this.unitId = jsonData.data.unitId;
        }
      });
    }
  },
  mounted: function () {
    var self = this;

    (function () {
      if (self.businessKey.length == 0) {
        return personPopedomApi.create();
      } else {
        return personPopedomApi.edit(self.businessKey);
      }
    })()
      .then((response) => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;
          self.oldPersonRoleId = self.formModel.personRoleId;
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch((error) => {
        self.$message.error(error + "");
      });
  }
};
</script>