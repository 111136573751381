import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData){
  return request.post(constant.serverUrl + "/base/personRoleInfo/pageList", formData);
}

function create(){
  return request.get(constant.serverUrl + "/base/personRoleInfo/create");
}

function edit(id){
  return request.get(constant.serverUrl + "/base/personRoleInfo/edit/" + id);
}

function add(formModel){
  return request.post(constant.serverUrl + "/base/personRoleInfo/add", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function update(formModel){  
  return request.post(constant.serverUrl + "/base/personRoleInfo/update", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function remove(id){
  return request.post(constant.serverUrl + "/base/personRoleInfo/delete/" + id);
}

function batchRemove(idList){
  return request.post(constant.serverUrl + "/base/personRoleInfo/batchDelete",idList,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function listByCompanyId(formData){
  return request.post(constant.serverUrl + "/base/personRoleInfo/listByCompanyId", formData);
}

function findBySceneId(formData){
  return request.post(constant.serverUrl + "/base/personRoleInfo/findBySceneId", formData);
}

function findAll(){
  return request.post(constant.serverUrl + "/base/personRoleInfo/findAll");
}

function findAllIcon(){
  return request.get(constant.serverUrl + "/base/personRoleInfo/findAllIcon");
}

function findRelatedIcon(personRoleId,mode){
  return request.get(constant.serverUrl + `/base/personRoleInfo/findRelatedIcon?personRoleId=${personRoleId}&mode=${mode}`);
}

function saveRelatedIcon(personRoleId,mode,idList){
  var formData = new FormData();

  formData.append("personRoleId",personRoleId);
  formData.append("mode",mode);
  formData.append("idList",idList.join(","));

  return request.post(constant.serverUrl + "/base/personRoleInfo/saveRelatedIcon",formData);
}

function findHaveIcon(formData){
  return request.post(constant.serverUrl + "/base/personRoleInfo/findHaveIcon", formData);
}

export default {
  pageList,create,edit,add,update,remove,batchRemove,listByCompanyId,findAll,
  findAllIcon,findRelatedIcon,saveRelatedIcon,findBySceneId,findHaveIcon
}