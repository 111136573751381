<template>
  <el-dialog
    :visible.sync="showDialog"
    title="生活记录"
    :modal-append-to-body="false"
    append-to-body
    :modal="true"
    style="text-align:left;"
    @close="closeDialog"
    :close-on-click-modal="false"
    width="60%"
  >
    <div>
      <!--
      要resetFields起作用，必须配置:model和prop
      -->
      <el-divider></el-divider>
      <el-form
        ref="queryForm"
        :model="queryModel"
        :rules="ruleValidate"
        inline
        class="demo-form-inline"
      >
        <el-row>
          <el-form-item label="日期" prop="queryDate">
            <el-date-picker
              size="mini"
              v-model="queryModel.queryDate"
              type="date"
              placeholder="选择日期"
              style="width:100%"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              size="mini"
              icon="ios-search"
              @click="handleQuery"
              :loading="loading"
            >查询</el-button>
          </el-form-item>
        </el-row>
      </el-form>
      <el-table
        :data="tableData"
        style="min-height:400px;"
        height="420px"
        v-loading="loading"
        stripe
      >
        <el-table-column type="index" label width="50"></el-table-column>
        <el-table-column prop="deviceName" label="打卡地点" width="180"></el-table-column>
        <el-table-column prop="recordTime" label="打卡时间" width="200"></el-table-column>
      </el-table>
    </div>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import personInfoApi from "@/api/base/personInfo";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  props: ["personId"],
  data() {
    var self = this;

    return {
      ruleValidate: {
        queryDate: [
          { required: true, message: "请选择查询日期", trigger: "blur" }
        ]
      },
      queryModel: { queryDate: "" },
      loading: false,
      tableData: [],
      field: "",
      direction: "",
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      showDialog: true,
    };
  },
  created() {
    //var nowDate = new Date;
    //this.queryModel.queryDate = new Date();
    //this.queryModel.queryDate = new Date();
    //this.queryModel.queryDate.setMonth(new Date().getMonth() - 1);

    this.getCurrentMonthFirst();
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    changePage() {
      var self = this;

      self.loading = true;

      var formData = new FormData();
      formData.append("queryDate", self.queryModel.queryDate);
      formData.append("personId", self.personId);
      personInfoApi
        .lifeRecordList(formData)
        .then(function(response) {
          self.loading = false;
          var jsonData = response.data.data;
          self.tableData = jsonData;
        })
        .catch(error => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;

      if (refreshed) {
        this.changePage();
      }
    },
    handleQuery() {
      var self = this;
      this.$refs["queryForm"].validate(valid => {
        if (valid) {
          self.changePage();
        }
      });
    },
    getCurrentMonthFirst() {
      var self = this;
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      if (month < 10) {
        month = "0" + month;
      }
      var data = date.getDate();
      if (data < 10) {
        data = "0" + data;
      }
      var startTime = year + "-" + month + "-" + data;
      self.queryModel.queryDate = startTime;
    },
  },
  mounted: function() {
    this.changePage();
  }
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>